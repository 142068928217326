.main {
  display: flex;
  margin-bottom: 5%;
  @media (max-width: 520px) {
    display: block;
  }
  .right {
    width: 75%;
    pre {
      font-size: 0.8rem;
      color: gray;
      text-align: center;
      margin: 0.5rem;
    }
  }
  .left {
    padding: 0 1rem;
    h2 {
      margin: 0 1.2rem;
    }
    .articles {
      display: flex;
      margin: 0.5rem 0;
      p {
        margin: 1rem;
      }
    }
  }
}
