.spacer {
  height: 17rem;
  background: var(--gray);
  margin-top: -7rem;
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (max-width: 1024px) {
  .home .content {
    align-items: center;
    text-align: center;
    padding-left: 0;
  }
  .home .info {
    margin: 1em;
  }
  .home .content .download_button {
    align-self: center;
    border-radius: 180px;
    max-width: 20rem;
  }

  .home .content .download_button p {
    word-wrap: wrap;
  }
  .products .product {
    display: flex;
    align-items: center;
  }
  .products .product .desc {
    max-width: 480px;
  }

  .efficient img {
    width: 90vw;
  }

  .about_us {
    align-items: center;
    text-align: justify;
    margin-right: 0px;
    max-width: 480px;
  }

  .about_us .quote {
    margin-top: 60px;
  }
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (max-width: 520px) {
  h2 {
    font-size: 2rem;
    margin: 0.225rem;
  }
  h3 {
    font-size: 1.4rem;
  }
  .home .content {
    flex-direction: column;
  }
  .home .content .right {
    height: unset;
  }

  .home .bg {
    background-position: right -40px top 0px;
  }
  .home .content img {
    width: 180px;
    height: auto;
  }
  .products .product {
    width: 80vw;
    margin-right: 0;
  }
  .about_us {
    margin: 1rem;
  }

  .about_us .quote {
    text-align: center;
  }

  .gallery .designed p {
    font-size: 24px;
    font-weight: 400;
  }

  .products .image {
    overflow-x: hidden;
  }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
}
