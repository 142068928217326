.efficient {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  .text {
    display: flex;
    flex-direction: horizontal;
    width: 60vw;
    margin: 2vh auto;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  p {
    font-family: var(--font-2);
    line-height: 2rem;
    text-align: left;
    color: var(--gray);
    width: 320px;
    word-wrap: normal;
    margin: 1rem 0;
  }

  img {
    width: 60vw;
    max-width: 960px;
    height: auto;
  }
  h3 {
    margin: 1rem 0;
  }
}
