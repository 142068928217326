div.title {
  display: flex;
  @media (max-width: 520px) {
    display: block;
  }
  width: 80%;
  margin: 1rem auto;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  // background-position: bottom;
  h1 {
    font-size: 3rem;
    @media (max-width: 520px) {
      font-size: 2rem;
    }
  }
  p {
    width: 25%;
    @media (max-width: 520px) {
      width: auto;
    }
  }
}
