.products {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  background-image: var(--bg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 520px) {
    background-image: unset;
  }
  & > h2 {
    font-size: 45px;
    margin: 5rem 0;
    @media (max-width: 768px) {
      margin: 0;
    }
    @media (max-width: 320px) {
      font-size: 40px;
    }
  }
  h3 {
    font-size: 32px;
    margin: 0.5em 0;
    @media (max-width: 320px) {
      font-size: 26px;
    }
  }
  .product {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 960px;
    justify-content: space-around;
    background-color: var(--gray-2);
    border: none;
    border-radius: 16px;
    padding: 1rem;
    margin: 10rem auto;
    @media (max-width: 768px) {
      margin: 5rem auto;
      margin-bottom: 7rem;
    }
    @media (max-width: 520px) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .image {
    }
    .desc {
      /* display: flex;
      flex-direction: column;
      align-items: center; */
      padding: 0px 40px;
      /* justify-content: flex-start; */
      ul {
        list-style: none;
        font-family: var(--font-2);
        padding: 0;
        li {
          display: flex;
          /* flex-wrap: nowrap; */
          /* margin-top: 1rem; */
          align-items: flex-start;
          /* font-weight: 600; */
          p {
            color: var(--gray);
            margin: 0;
            font-weight: 600;
            margin-bottom: 1.2em;
            font-size: 20px;
            padding: 0;
            @media (max-width: 320px) {
              font-size: 1rem;
            }
          }
          img {
            margin-right: 1ch;
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  .product2 {
    flex-direction: row-reverse;
    width: 80%;
    display: flex;
    position: relative;
    justify-content: flex-end;

    .image {
      position: absolute;
      background-image: var(--bg);
      width: 100%;
      height: 130%;
      top: -25%;
      left: 20%;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;

      @media (max-width: 1024px) {
        left: 5%;
      }
      @media (max-width: 520px) {
        top: 63%;
        background-size: cover;
        height: 60%;
        width: 90%;
        left: 10%;
      }
      @media (max-width: 320px) {
        top: 65%;
        background-size: cover;
        height: 75%;
        width: 142%;
        left: -42%;
      }
    }
  }
  .product3 {
    width: 80%;
    display: flex;
    position: relative;

    .image {
      position: absolute;
      background-image: var(--bg);
      width: 100%;
      height: 140%;
      top: -35%;
      right: 30%;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      @media (max-width: 768px) {
        right: 40%;
      }
      @media (max-width: 520px) {
        top: 63%;
        background-size: cover;
        height: 60%;
        width: 25%;
        left: 75%;
      }
      @media (max-width: 320px) {
        width: 32%;
        left: 68%;
      }
    }
    .desc {
      margin-left: auto;
    }
  }
}
