.specs {
  display: flex;
  justify-content: center;
  padding: 5rem;
  background-image: var(--bg);
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 520px) {
    display: none;
  }
  h2 {
    font-size: 2rem;
  }
  .center {
    height: 80%;
    width: 80%;
    .table {
      .headings {
        display: flex;
        justify-content: space-between;
        color: #707070;
        padding: 1.5rem 0;
        span {
          width: 33.33%;
          h3 {
            font-family: "Oswald";
            font-size: 1.5rem;
            opacity: 0.4;
          }
        }
      }
      hr: {
        background-color: #707070;
        opacity: 0.25;
      }
      .body {
        display: flex;
        justify-content: space-between;
        padding: 1.5rem 0;
        ul {
          width: 33.33%;
          li {
            font-size: 1.2rem;
            padding: 1rem 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            min-height: 4.7rem;
          }
        }
      }
    }
  }
}
