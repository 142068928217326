footer {
  background-color: var(--gray);
  background-image: var(--bg);
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  display: flex;
  @media (max-width: 520px) {
    display: block;
  }
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .contact,
  .footer_about {
    padding: 2rem;
    width: 50%;
    @media (max-width: 520px) {
      width: auto;
    }
    .heading {
      h3 {
        padding: 1rem;
        padding-left: 0;
      }
      display: flex;
      justify-content: space-between;
    }
    .row {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-evenly;
      padding: 1rem;
      padding-left: 0;
      @media (max-width: 520px) {
        flex-direction: column;
      }
      li {
        display: flex;
        align-items: center;
        padding: 1rem;
        padding-left: 0;
        i {
          margin-right: 0.5rem;
        }
      }
      .address p {
        width: 50%;
      }
    }
  }
  .footer_about {
    .row {
      justify-content: unset;
      h3 {
        margin-left: 1.5rem;
      }
      @media (max-width: 520px) {
        width: 50%;
      }
    }
  }
  .image {
    background-image: var(--bg);
    background-position: center;
    background-size: cover;
    height: 35rem;
    width: 25%;
    position: absolute;
    top: -70%;
    right: 0;
    @media (max-width: 1024px) {
      height: 30rem;
      width: 20%;
      top: -40%;
    }
    @media (max-width: 768px) {
      height: 30rem;
      top: -70%;
      width: 15%;
    }
    @media (max-width: 520px) {
      width: 40%;
      top: 40%;
    }
  }
}
.show-on-mobile {
  display: none;
  @media (max-width: 520px) {
    display: unset;
  }
}
ul.team {
  @media (max-width: 520px) {
    width: auto;
  }
}
.hide-on-mobile {
  @media (max-width: 520px) {
    display: none;
  }
}
