* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
:root {
  --blue: #009ea0;
  --blue-2: #1ac0c6;
  --white: #ffffff;
  --black: #031213;
  --gray: #3b4555;
  --gray-2: #e1ebf2;
  --gray-3: #f0f6fa;
  --font-1: "Montserrat", helvetica;
  --font-2: "Source Sans Pro", helvetica;
}

body {
  background-color: var(--gray-3);
  @media (max-width: 520px) {
    background-color: unset;
  }

  h2 {
    font-family: var(--font-1);
    font-weight: 700;
  }

  h3 {
    font-family: var(--font-1);
    font-size: 32px;
    font-weight: 700;
  }

  p,
  b,
  a {
    font-family: var(--font-2);
  }
  img,
  video {
    display: block;
    width: 100%;
    height: 100%;
  }
  ul,
  ol {
    list-style: none;
  }
  button:active,
  button:focus {
    outline: 0;
  }
  a {
    text-decoration: none;
    color: white;
  }
  .blue {
    color: var(--blue);
  }
}
