.header {
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
  .download {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .logo {
    height: 50%;
    width: 10%;
    margin-left: 5rem;

    @media (max-width: 520px) {
      width: auto;
      margin-left: 1rem;
    }
  }
}
