.press {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  align-items: center;
  justify-content: center;
  padding: 60px 0;
  position: relative;
  i {
    position: absolute;
    width: 2%;
    top: 5%;
    left: 10%;
  }
  .images {
    display: flex;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    margin: 4rem 0;
    justify-content: space-evenly;
    .image {
      @media (max-width: 520px) {
        width: 25%;
      }
    }
  }
}
