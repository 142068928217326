.spec-mobile {
  margin: 1rem;
  padding: 1rem;
  @media (min-width: 520px) {
    display: none;
  }
  h2 {
    font-size: 1.5rem;
    @media (max-width: 320px) {
      font-size: 1.25rem;
    }
  }
  .buttons {
    display: flex;
    margin: 1rem 0.5rem;
    button {
      margin-right: 0.5rem;
      opacity: 0.5;
      border: 0;
      background: transparent;
    }
    button.active {
      position: relative;
      border: 0;
      background: transparent;
      opacity: 1;
      &::after {
        position: absolute;
        content: " ";
        height: 1%;
        width: 50%;
        background-color: var(--blue);
        bottom: -10%;
        left: 2%;
      }
    }
  }
  .table {
    background-color: var(--gray-3);
    padding: 5%;
    border-radius: 5%;
    margin: 2rem auto;
    h3 {
      font-size: 1rem;
    }

    ul {
      li {
        font-size: 0.8rem;
        padding: 1rem 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 4.7rem;
        p {
          width: 33.33%;
        }
      }
    }
  }
}
