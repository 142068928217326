.home {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 100vh;
  background-image: var(--bg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  justify-content: center;
  transition: all 2ms ease-in-out;
  @media (max-width: 768px) {
    background-position: right;
  }
  @media (max-width: 520px) {
    flex-direction: column;
  }
  .left {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    width: 100%;
    @media (max-width: 768px) {
      justify-content: flex-start;
    }
    .image {
      backdrop-filter: blur(20px);
      border-top-right-radius: 32px;
      border-bottom-right-radius: 32px;
      background-color: rgba(255, 255, 255, 0.2);
      padding: 20px;

      width: 75%;
      @media (max-width: 768px) {
        margin-top: 7rem;
      }
    }
  }

  .right {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    flex-direction: column;
    height: 100%;
  }

  .info {
    display: block;
    max-width: 520px;
    margin-left: 7rem;
    h2 {
      color: var(--white);
      font-size: 45px;
    }
  }
}
