.features {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--gray);
  padding: 1rem;
  h3 {
    color: var(--white);
    margin: 1rem 0;
  }

  .cards {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin: 1rem 0;
  }

  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background-color: var(--gray-3);
    width: 240px;
    height: 280px;
    margin: 2rem;
    border-radius: 1.2rem;
  }
  img {
    width: 80px;
    height: 90px;
    margin: auto 1.2em;
  }

  p {
    font-family: var(--font-1);
    color: var(--gray);
    margin: auto 1.2em;
    text-align: center;
  }
}
