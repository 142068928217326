main.certificate-main {
  margin-bottom: 15%;

  h1 {
    font-size: 2.7rem;
    margin-right: 2rem;
  }
  p {
    width: 26%;
    @media (max-width: 520px) {
      width: auto;
    }
  }
  .certificates {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    @media (max-width: 520px) {
      grid-template-columns: repeat(2, 1fr);
    }
    grid-gap: 1rem;
    width: 80%;
    margin: 10% auto;
  }
}
