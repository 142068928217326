.download_button {
  align-self: flex-end;
  background-color: var(--blue);
  display: block;
  width: 50%;
  border-top-left-radius: 38px;
  border-bottom-left-radius: 38px;
  cursor: pointer;

  margin-bottom: 3rem;
  a {
    font-family: var(--font-2);
    font-weight: 400;
    line-height: 2.4rem;
    font-size: 16px;
    color: var(--white);
    padding-left: 30px;
    text-decoration: none;
    @media (max-width: 320px) {
      font-size: 15px;
    }
  }

  @media (max-width: 520px) {
    width: 100%;
  }
}
