.video {
  .video-card {
    width: 80%;
    margin: 4rem auto;
    video {
      border-radius: 5%;
      filter: drop-shadow(0rem 1rem 1rem black);
      object-fit: cover;
    }
  }
}
