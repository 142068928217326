.gallery {
  background-color: var(--gray);
  background-image: var(--bg);
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: -10rem;
  @media (max-width: 768px) {
    background-position: bottom;
    margin-top: 0;
  }
  .content {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }
  h3 {
    color: var(--white);
    @media (max-width: 768px) {
      margin: 0;
    }
  }
  .title {
    margin-top: 20rem;
    @media (max-width: 768px) {
      margin-top: 1rem;
    }
  }
  .subtitle {
    margin-top: 5rem;
    @media (max-width: 768px) {
      margin-top: 0;
    }
  }
  .images {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    @media (max-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
      margin: 1.5rem 0;
    }
    @media (max-width: 520px) {
      grid-template-columns: repeat(2, 1fr);
    }
    margin: 5rem 0;
    @media (max-width: 1024px) {
      max-width: 90vw;
      overflow: auto;
    }

    .image {
      margin: 1.2em;
      filter: drop-shadow(0rem 1rem 1.5rem black);
      @media (max-width: 1024px) {
        filter: drop-shadow(0rem 0.25rem 0.5rem black);
      }
      @media (max-width: 768px) {
        width: 75%;
        height: 75%;
      }
      @media (max-width: 520px) {
        width: 65%;
        height: auto;
        justify-self: center;
      }
    }
    .last {
      @media (max-width: 520px) {
        grid-column: span 2;
        width: 35%;
      }
    }
  }
  .designed {
    display: flex;
    flex-direction: row;
    margin: 8vh auto;
    padding: 8px 2ch;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--gray-3);
    border-radius: 8px;
    @media (max-width: 520px) {
      flex-direction: column;
      width: 80%;
      padding: 5rem;
    }
    img {
      padding: 0;
      margin: 0;
    }
    p {
      color: var(--white);
      font-weight: 600;
      text-align: center;
      margin: 0 2ch;
      @media (max-width: 520px) {
        width: 55vw;
        margin: 1rem 0;
      }
    }
  }
}

.about {
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  align-items: center;
  margin-top: 40px;
  width: 80%;
  margin-bottom: 60px;
  .about_us {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-self: stretch;
    margin-right: 2rem;
    margin-bottom: 1rem;
    height: 100%;

    p {
      color: var(--gray-2);
      font-family: var(--font-2);
      padding: 0;
    }
    .quote {
      margin-top: auto;
      margin-bottom: 2rem;
      text-align: right;
      background-image: url(../../assets/quote_bg.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: left;
      padding: 2.2rem;
      text-align: left;
      p {
        padding: 1rem;
        font-size: 1.25rem;
      }

      b {
        color: var(--gray-2);
        text-align: right;
        padding: 1rem;
        font-size: 1rem;
      }
    }
  }
  img {
    width: 38vw;
    height: auto;
    border-radius: 5%;
    @media (max-width: 768px) {
      width: 75vw;
    }
  }
}
