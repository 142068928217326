.distributors {
  main.content {
    background-image: var(--bg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: 520px) {
      background-image: unset;
    }
    div {
      width: 65%;
      margin: 1rem auto;
      @media (max-width: 520px) {
        width: 75%;
      }
    }
    .diss {
      display: flex;
      justify-content: space-evenly;
      @media (max-width: 520px) {
        display: block;
        margin: 1rem;
      }
      .dis {
        h2 {
          color: #1ac0c6;
        }
        h4 {
          margin-left: 4%;
        }
        ul {
          li {
            display: flex;
            align-items: center;
            padding: 1rem;
            i {
              margin-right: 0.5rem;
              width: 7%;
            }
          }
        }
      }
    }
  }
}
